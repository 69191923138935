import styled from 'styled-components'
import { Link } from 'gatsby'
import * as Logo from '../../../assets/logo.svg'
import { Mail } from '@styled-icons/feather/Mail'
import { Facebook } from '@styled-icons/boxicons-logos/Facebook'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'

export const MenuContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  max-width: 100%;
  background-color: rgba(255, 247, 240, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: transform 0.4s;
  transform: ${props => (props.opened ? 'translateX(0%)' : 'translateX(100%)')};
`

export const LogoContainer = styled.li`
  margin-bottom: 50px;
`

export const MenuItem = styled.li`
  margin-bottom: 20px;
  position: relative;

  :after {
    content: '';
    height: 1px;
    width: 0;
    display: block;
    position: absolute;
    bottom: -8px;
    left: 50%;
    background-color: ${({ theme }) => theme.color.secondary};
    transform: translateX(-50%);
    transition: width 0.3s;
  }

  :hover {
    :after {
      width: 30px;
    }
  }
`

export const MenuLink = styled(Link)`
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
  color: ${({ theme }) => theme.color.secondary};

  @media (min-width: ${props => props.theme.size.medium}) {
    font-size: 18px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 20px;
  }
`

export const MenuLinkContainer = styled.div``

export const MenuNav = styled.nav`
  text-align: center;
`

export const MenuNavWrapper = styled.div`
  width: 100%;
  padding-top: 120px;
  padding-bottom: 100px;
  overflow-y: auto;
`

export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledLogo = styled(Logo)`
  height: 110px;
  transition: height 200ms;
  margin-top: 5px;

  @media (min-width: ${props => props.theme.size.medium}) {
  }

  @media (min-width: ${props => props.theme.size.large}) {
  }
`

export const IconsList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
`

export const IconListItem = styled.li`
  margin: 10px 10px;
`

export const MailIcon = styled(Mail)`
  color: white;
  height: 22px;
  display: block;
`
export const InstagramIcon = styled(Instagram)`
  color: white;
  height: 22px;
  display: block;
`

export const FacebookIcon = styled(Facebook)`
  color: white;
  height: 26px;
  display: block;
`
