import React from 'react'
import * as S from '../../styles/components/links/linkIconStyle'

export default function LinkIcon(props) {
  return (
    <S.LinkIcon href={props.href} target="_blank">
      {props.children}
    </S.LinkIcon>
  )
}
