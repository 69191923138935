import styled from 'styled-components'
import { Mail } from '@styled-icons/feather/Mail'
import { Facebook } from '@styled-icons/boxicons-logos/Facebook'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'

export const Footer = styled.footer`
  width: 100%;
`

export const FooterNav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
`

export const IconsList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
`

export const IconListItem = styled.li`
  margin: 10px 10px;
`

export const FooterList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-bottom: 10px;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
`

export const ListItem = styled.li`
  margin: 15px;
  padding: 0;
  position: relative;
  color: ${({ theme }) => theme.color.secondary};

  :after {
    content: '';
    height: 1px;
    width: 0;
    display: block;
    position: absolute;
    bottom: -8px;
    left: 50%;
    background-color: ${({ theme }) => theme.color.secondary};
    transform: translateX(-50%);
    transition: width 0.3s;
  }

  :hover {
    :after {
      width: 30px;
    }
  }
`

export const FooterBottom = styled.div`
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.color.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FooterBottomLink = styled.a`
  color: #636363;
  font-family: 'Book Antiqua';
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  transition: color 0.3s;

  :hover {
    color: #b4b4b4;
  }
`

export const MailIcon = styled(Mail)`
  color: white;
  height: 22px;
  display: block;
`
export const InstagramIcon = styled(Instagram)`
  color: white;
  height: 22px;
  display: block;
`

export const FacebookIcon = styled(Facebook)`
  color: white;
  height: 26px;
  display: block;
`
