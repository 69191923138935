import styled, { createGlobalStyle, keyframes } from 'styled-components'
import { Link } from 'gatsby'
import { Mail } from '@styled-icons/feather/Mail'
import { Address } from '@styled-icons/entypo/Address'
import { Telephone } from '@styled-icons/bootstrap/Telephone'
import { Facebook } from '@styled-icons/boxicons-logos/Facebook'
import { Time } from '@styled-icons/ionicons-outline/Time'

export const theme = {
  color: {
    primary: '#fffcf8',
    secondary: '#424241',
    third: '#EACDB2',
    fourth: '#E3D3C8',
    fifth: '#F4F1EE',
    sixth: '#F7E9DC',
    gold: '#D8BF9D',
  },
  size: {
    tiny: '360px',
    verySmall: '480px',
    small: '600px',
    medium: '768px',
    large: '1024px',
    veryLarge: '1280px',
    huge: '1800px',
  },
}

const fadeIn = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0);
  }

  100% {
    background-color: rgba(0, 0, 0, 0.6);
  }
`

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        box-sizing: border-box;
    }

    body {
      overflow-x: hidden;
    }
`

export const Container = styled.div`
  position: relative;
  z-index: 2;
  width: calc(100% - 30px);
  margin: 0 auto;

  @media (min-width: ${props => props.theme.size.medium}) {
    width: 90%;
  }

  @media (min-width: ${props => props.theme.size.huge}) {
    width: 100%;
    max-width: 1680px;
  }
`

export const Shape = styled.div`
  display: none;
  position: absolute;
  opacity: 0.7;

  @media (min-width: ${props => props.theme.size.small}) {
    display: block;
  }
`

export const MainShape = styled(Shape)`
  top: -83px;
  right: -129px;
  max-width: 80%;

  svg {
    transform: scale(1.5);
  }

  @media (min-width: ${props => props.theme.size.large}) {
    top: -41px;
    right: -82px;
  }

  @media (min-width: ${props => props.theme.size.veryLarge}) {
    top: -93px;

    svg {
      transform: scale(2.5);
    }
  }
`

export const MainContainer = styled.main`
  padding-top: 125px;
  padding-bottom: 0;
  background-color: ${({ theme }) => theme.color.primary};
  min-height: calc(100vh - 260px);
  display: block;
  position: relative;
  overflow-x: hidden;

  @media (min-width: ${props => props.theme.size.large}) {
    padding-top: 160px;
    padding-bottom: 100px;
    min-height: calc(100vh - 260px);
  }
`

export const MainText = styled.p`
  font-family: 'Book Antiqua';
  font-size: 14px;
  color: ${({ theme }) => theme.color.secondary};

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 16px;
  }
`

export const MainHeader = styled.h1`
  font-family: 'Yeseva One';
  font-weight: 400;
  font-size: 30px;
  color: ${({ theme }) => theme.color.secondary};

  @media (min-width: ${props => props.theme.size.tiny}) {
    font-size: 35px;
  }

  @media (min-width: ${props => props.theme.size.verySmall}) {
    font-size: 40px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 50px;
  }

  @media (min-width: ${props => props.theme.size.veryLarge}) {
    font-size: 50px;
  }

  @media (min-width: ${props => props.theme.size.huge}) {
    font-size: 60px;
  }
`

export const MainHeaderHidden = styled.h1`
  font-family: 'Yeseva One';
  font-weight: 400;
  font-size: 60px;
  color: ${({ theme }) => theme.color.secondary};
  visibility: hidden;
`

export const ModalsContainer = styled.div``

export const Overlay = styled.span`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: ${fadeIn} 0.2s linear forwards;
  opacity: 0.5;
  z-index: 90;
`

export const LinkButton = styled(Link)`
  padding: 14px 30px;
  font-family: 'Book Antiqua';
  display: inline-block;
  text-align: center;
  background-color: ${({ theme }) => theme.color.third};
  opacity: 1;
  border-radius: 55px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.color.secondary};
  position: relative;
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-decoration: none;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.4);
  }

  &:active {
    opacity: 0.8;
    top: 1px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 18px;
    padding: 19px 35px;
  }
`

export const LinkItem = styled(Link)`
  font-family: 'Book Antiqua';
  font-size: 14px;
  color: ${({ theme }) => theme.color.secondary};
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;

  :visited {
    text-decoration: none;
    color: ${({ theme }) => theme.color.secondary};
  }

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 16px;
  }
`

export const SubHeader = styled.h2`
  font-family: 'Yeseva One';
  font-weight: 400;
  font-size: 30px;
  color: ${({ theme }) => theme.color.secondary};
  text-align: center;

  @media (min-width: ${props => props.theme.size.medium}) {
    font-size: 40px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 50px;
  }

  @media (min-width: ${props => props.theme.size.veryLarge}) {
    font-size: 50px;
  }

  @media (min-width: ${props => props.theme.size.huge}) {
    font-size: 72px;
  }
`

export const SubSubHeader = styled.h3`
  font-family: 'Book Antiqua';
  font-size: 20px;
  color: ${({ theme }) => theme.color.secondary};
  margin-bottom: 30px;

  @media (min-width: ${props => props.theme.size.tiny}) {
    font-size: 20px;
  }

  @media (min-width: ${props => props.theme.size.verySmall}) {
    font-size: 25px;
    margin-bottom: 40px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 30px;
    margin-bottom: 50px;
  }

  @media (min-width: ${props => props.theme.size.veryLarge}) {
    font-size: 40px;
  }

  @media (min-width: ${props => props.theme.size.huge}) {
    font-size: 40px;
  }
`

export const ContactInfoSection = styled.section`
  position: relative;
  padding-top: 45px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${props => props.theme.size.large}) {
    flex-direction: row;
  }
`

export const ContactInfoContainer = styled.div`
  @media (min-width: ${props => props.theme.size.small}) {
    width: 100%;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    width: 45%;
  }
`

export const ContactInfoGmapsContainer = styled.div`
  width: 100%;
  height: 430px;
  max-height: 80vh;
  position: relative;
  overflow: hidden;
  border-radius: 9px;

  @media (min-width: ${props => props.theme.size.medium}) {
    height: 550px;
  }

  @media (min-width: ${props => props.theme.size.large}) {
    width: 55%;
    height: 670px;
    max-height: unset;
  }
`

export const ContactInfoMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;

  @media (min-width: ${props => props.theme.size.large}) {
    flex-direction: row;
    align-items: center;
    margin-top: 90px;
  }
`

export const MailIcon = styled(Mail)`
  color: white;
  height: 22px;
  display: block;
`
export const AddressIcon = styled(Address)`
  color: white;
  height: 22px;
  display: block;
`
export const TelephoneIcon = styled(Telephone)`
  color: white;
  height: 20px;
  display: block;
`
export const FacebookIcon = styled(Facebook)`
  color: white;
  height: 26px;
  display: block;
`
export const TimeIcon = styled(Time)`
  color: white;
  height: 24px;
  display: block;
`
