import React from 'react'
import * as S from '../styles/components/footerStyle'
import * as G from '../styles/globalStyle'
import LinkIcon from './links/linkIcon'

export default function Footer() {
  return (
    <S.Footer>
      <S.FooterNav>
        <S.FooterList>
          <S.ListItem>
            <G.LinkItem to="/">HOME</G.LinkItem>
          </S.ListItem>
          <S.ListItem>
            <G.LinkItem to="/o-nas">O NAS</G.LinkItem>
          </S.ListItem>
          <S.ListItem>
            <G.LinkItem to="/zabiegi">ZABIEGI</G.LinkItem>
          </S.ListItem>
          <S.ListItem>
            <G.LinkItem to="/zespol">ZESPÓŁ</G.LinkItem>
          </S.ListItem>
          <S.ListItem>
            <G.LinkItem to="/kontakt">KONTAKT</G.LinkItem>
          </S.ListItem>
          <S.ListItem>
            <G.LinkItem to="/galeria">GALERIA</G.LinkItem>
          </S.ListItem>
        </S.FooterList>
        <S.IconsList>
          <S.IconListItem>
            <LinkIcon href="https://www.facebook.com/SalonEvitaKosmetykaProfesjonalna">
              <S.InstagramIcon />
            </LinkIcon>
          </S.IconListItem>
          <S.IconListItem>
            <LinkIcon href="https://www.facebook.com/SalonEvitaKosmetykaProfesjonalna">
              <S.FacebookIcon />
            </LinkIcon>
          </S.IconListItem>
          <S.IconListItem>
            <LinkIcon href="mailto:salon.evita@wp.pl">
              <S.MailIcon />
            </LinkIcon>
          </S.IconListItem>
        </S.IconsList>
      </S.FooterNav>
      <S.FooterBottom>
        <S.FooterBottomLink
          target="_blank"
          href="https://www.dawidmieszczak.pl"
        >
          Designed and Developed by Dawid Mieszczak
        </S.FooterBottomLink>
      </S.FooterBottom>
    </S.Footer>
  )
}
