import React from 'react'
import { ThemeProvider } from 'styled-components'
import {
  GlobalStyle,
  theme,
  MainContainer,
  MainShape,
} from '../styles/globalStyle'
import Header from '../components/header'
import Footer from '../components/footer'
import * as Lines1 from '../assets/images/static/lines.svg'
import { Helmet } from 'react-helmet'

export default function Layout({ children }) {
  return (
    <React.Fragment>
      <Helmet htmlAttributes={{ lang: 'pl-PL' }}>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Salon Evita</title>
        <meta
          name="description"
          content="Salon Kosmetyki Profesjonalnej „Evita” został założony w roku 2010
              przez Ewę Plekaniec, kosmetyczkę z 28-letnim doświadczeniem w
              branży kosmetologicznej. Obecnie za jego sukcesem stoi pięć
              wykwalifikowanych pasjonatek. Jest to miejsce wyróżniające się
              indywidualnym, ciepłym podejściem do klienta, wysokim stopniem
              profesjonalizmu i niegasnącą pasją."
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header></Header>
        <MainContainer>
          <MainShape>
            <Lines1 />
          </MainShape>
          {children}
        </MainContainer>
        <Footer></Footer>
      </ThemeProvider>
    </React.Fragment>
  )
}
