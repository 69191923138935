import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import * as S from '../styles/components/headerStyle'
import * as G from '../styles/globalStyle'
import { useScrollPosition } from '../utils/useScrollPosition'
import Navigation from './navigation/navigation'

export default function Header() {
  const [isMenuOpened, setMenuOpen] = useState(false)
  const [editOnScroll, setEditOnScroll] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpened)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  useScrollPosition(
    ({ currPos }) => {
      const isEdited = currPos.y < -100
      setEditOnScroll(isEdited)
    },
    [editOnScroll],
    false,
    false,
    200
  )

  return (
    <>
      <S.Header scrolled={editOnScroll ? 1 : 0}>
        <S.TopBarContainer
          scrolled={editOnScroll ? 1 : 0}
          isMenuOpened={isMenuOpened ? 1 : 0}
        >
          <S.HeaderContainer scrolled={editOnScroll ? 1 : 0}>
            <S.LogoLink to="/">
              <S.StyledLogo scrolled={editOnScroll ? 1 : 0} />
            </S.LogoLink>
            <S.MenuButton onClick={toggleMenu}>
              <S.MenuButtonText>MENU</S.MenuButtonText>
              <S.MenuHamburgerContainer
                opened={isMenuOpened}
              ></S.MenuHamburgerContainer>
            </S.MenuButton>
          </S.HeaderContainer>
        </S.TopBarContainer>
        <Navigation opened={isMenuOpened} />
      </S.Header>
      {isMenuOpened && <G.Overlay onClick={closeMenu}></G.Overlay>}
    </>
  )
}
