import styled from 'styled-components'

export const LinkIcon = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  background-color: ${({ theme }) => theme.color.sixth};
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;

  :hover {
    transform: translateY(-2px);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  }
`
