import React from 'react'
import * as S from '../../styles/components/navigation/navigationStyle'
import LinkIcon from '../links/linkIcon'

export default function Navigation(props) {
  React.useEffect(() => {
    if (document && props.opened) {
      document.body.style.overflow = 'hidden'
    } else if (document && !props.opened) {
      document.body.style.overflow = ''
    }
  }, [props.opened])

  return (
    <S.MenuContainer opened={props.opened}>
      <S.MenuNavWrapper>
        <S.MenuNav>
          <S.MenuList>
            <S.LogoContainer>
              <S.MenuLink to="/">
                <S.StyledLogo />
              </S.MenuLink>
            </S.LogoContainer>
            <S.MenuItem>
              <S.MenuLink to="/">HOME</S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/o-nas">O NAS</S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/zabiegi">ZABIEGI</S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/zespol">ZESPÓŁ</S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/kontakt">KONTAKT</S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/cennik">CENNIK</S.MenuLink>
            </S.MenuItem>
            <S.MenuItem>
              <S.MenuLink to="/galeria">GALERIA</S.MenuLink>
            </S.MenuItem>
          </S.MenuList>
          <S.IconsList>
            <S.IconListItem>
              <LinkIcon href="https://www.facebook.com/SalonEvitaKosmetykaProfesjonalna">
                <S.InstagramIcon />
              </LinkIcon>
            </S.IconListItem>
            <S.IconListItem>
              <LinkIcon href="https://www.facebook.com/SalonEvitaKosmetykaProfesjonalna">
                <S.FacebookIcon />
              </LinkIcon>
            </S.IconListItem>
            <S.IconListItem>
              <LinkIcon href="mailto:salon.evita@wp.pl">
                <S.MailIcon />
              </LinkIcon>
            </S.IconListItem>
          </S.IconsList>
        </S.MenuNav>
      </S.MenuNavWrapper>
    </S.MenuContainer>
  )
}
