import { Link } from 'gatsby'
import styled from 'styled-components'

import * as Logo from '../../assets/logo.svg'

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  z-index: 100;
`

export const LogoLink = styled(Link)``
export const StyledLogo = styled(Logo)`
  height: 60px;
  transition: height 200ms;
  margin-top: 5px;

  @media (min-width: ${props => props.theme.size.medium}) {
    height: ${props => (props.scrolled ? '60px' : '70px')};
  }

  @media (min-width: ${props => props.theme.size.veryLarge}) {
    height: ${props => (props.scrolled ? '60px' : '120px')};
  }
`

export const TopBarContainer = styled.div`
  width: 100%;
  height: 100%;
  transition: background-color 200ms, box-shadow 200ms;
  background-color: ${props =>
    props.scrolled || props.isMenuOpened
      ? 'rgba(255, 247, 240, 1)'
      : 'rgba(255, 247, 240, 0)'};
  position: relative;
  box-shadow: ${props =>
    props.isMenuOpened
      ? '0px 5px 14px 0px rgb(0 0 0 / 5%)'
      : '0px 5px 14px 0px rgb(0 0 0 / 0%)'};
  z-index: 101;
`

export const HeaderContainer = styled.div`
  width: 90%;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  transition: height 200ms;

  @media (min-width: ${props => props.theme.size.medium}) {
    height: ${props => (props.scrolled ? '80px' : '90px')};
  }

  @media (min-width: ${props => props.theme.size.veryLarge}) {
    height: ${props => (props.scrolled ? '80px' : '140px')};
  }

  @media (min-width: ${props => props.theme.size.huge}) {
    width: 100%;
    max-width: 1760px;
  }
`
export const MenuButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  z-index: 200;
  background: none;
  border: none;
  outline: none;
`

export const MenuButtonText = styled.p`
  font-size: 16px;
  font-family: 'Yeseva One';
  font-weight: 400;
  margin-right: 6px;
  line-height: 11px;
  color: ${({ theme }) => theme.color.secondary};

  @media (min-width: ${props => props.theme.size.large}) {
    font-size: 20px;
    line-height: 20px;
  }
`

export const MenuHamburgerContainer = styled.span`
  display: block;
  height: 11px;
  width: 21px;
  position: relative;

  &::before {
    content: '';
    transform-origin: 4px center;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.color.secondary};
    position: absolute;
    top: 0;
    transition: transform 0.4s;
    transform: ${props => (props.opened ? 'rotate(45deg)' : 'rotate(0deg)')};
  }

  &::after {
    content: '';
    transform-origin: 4px center;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.color.secondary};
    position: absolute;
    bottom: 0;
    transition: transform 0.4s;
    transform: ${props => (props.opened ? 'rotate(-45deg)' : 'rotate(0deg)')};
  }
`
